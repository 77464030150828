import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { FetchAllObligations } from '../actions/company.action';
import { Obligation } from 'src/app/interfaces/obligation.interface';

export class ObligationsStateModel {
    isObligationsLoaded:  boolean

    obligations:          Obligation[]
}

@State<ObligationsStateModel>({
  name: 'obligationState',
  defaults: {
    isObligationsLoaded: false,
    
    obligations: []
  },
})
@Injectable()
export class ObligationsState {
  constructor( private util: Commons, private compService: CompanyService ) {}

  /* Selector to get selected application */
  @Selector()
  static getObligations(state: ObligationsStateModel) {
    return state.obligations
  }

  @Selector()
  static isObligationsLoaded(state: ObligationsStateModel) {
    return state.isObligationsLoaded
  }


  @Action(FetchAllObligations)
  fetchObligations({getState, setState}: StateContext<FetchAllObligations>){
    const state = getState()

    return this.compService.getAllObligations$().pipe(tap(
        res=>{
            ( res.statusCode === "100" )
            && this.util.updateState( state, { isObligationsLoaded: true, obligations: res['data'] }, setState )
            || this.util.updateState( state, { isObligationsLoaded: false, obligations: [] }, setState )
        },
        error=> this.util.updateState( state, { isObligationsLoaded: false, obligations: [] }, setState )
    ))

  }

}
