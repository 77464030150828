<div class="main pt-30" id="raise">

    <div class="d-flex align-items-center mb-30">
        <img width="20px" (click)="closeQuery()" class="cursor mr-15" src="../../../../../assets/img/icons/icon-(32).png" alt="back">
        <h5>Grievance/Query</h5>
    </div>

    <form #raise='ngForm' (ngSubmit)="raiseQuery()">
        <div class="input-field">
            <label for="">
                <small>Query Type</small>
            </label>
            <select name="queryType" id="queryType" [(ngModel)]="selectedQueryType" required>
                <option value="" selected disabled>Select your query type</option>
                <option *ngFor="let query of queryTypes" [value]=query>{{ query }}</option>
            </select>
        </div>
    
        <div class="input-field">
            <label for="">
                <small>Comment</small>
            </label>
            <textarea name="queryMessage" id="queryMessage" [(ngModel)]="queryMessage" required class="w-100" rows="5" placeholder="Enter your comment"></textarea>
        </div>
    
        <button class="primary-btn" [disabled]="raise.form.invalid">SUBMIT</button>
    </form>

</div>