<nav class="navbar navbar-main navbar-expand-lg px-2 py-2 bg-white  bgmobile shadow-none border-radius-xl"
    id="navbarBlur" navbar-scroll="true">
    <div class="container-fluid py-1 px-0">
        <nav class="navbar top-navbar navbar-light px-0 px-lg-5" aria-label="breadcrumb">
            <a class="btn border-0 mr-10" id="menu-btn"><i class="fal fa-bars"></i></a>


            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6">

                <li class="breadcrumb-item text-sm text-dark text-white active text-left" aria-current="page">Welcome {{
                    username }}

                    <h3 class="font-weight-bolder mb-0">{{ heading }}</h3>
                </li>

            </ol>

        </nav>


        <div class=" mt-sm-0 mt-2 me-md-0 me-sm-4 header_right d-flex justify-content-between align-items-center "
            id="navbar">

            <!-- Button trigger modal -->
             <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-toggle="modal"
                data-bs-target="#staticBackdrop" #openTermsDialog>

            </button>

            <!-- Modal -->
             <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header text-center" style="justify-content: center !important;">
                            <h5 class="modal-title" id="staticBackdropLabel">Agreement</h5>

                        </div>
                        <div class="modal-body">

                            By Clicking Proceed, you agree to our <a href="https://getgrowthcapital.co/terms" target="_blank"
                                style="color: #009c9a;font-weight: bold;">Terms & Conditions</a> and <a
                                href="https://getgrowthcapital.co/privacy-policy" target="_blank"
                                style="color: #009c9a;font-weight: bold;">Privacy Policy</a>
                        </div>
                        <div class="modal-footer text-center">
                            <button type="button" class="btn btn-green" data-bs-dismiss="modal"
                                (click)="updateTnc(true)">Proceed</button>
                        </div>
                    </div>
                </div>
            </div> 
         
         
         
         <!-- Button trigger modal -->
         <!-- <button type="button" class="btn btn-primary hiddedButton" data-bs-toggle="modal" data-bs-toggle="modal"
         data-bs-target="#staticBackdrop" #openTermsDialog>

     </button> -->

     <!-- Modal -->
     <!-- <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
             <div class="modal-content">
                 <div class="modal-header text-center" style="justify-content: center !important;">
                     <h5 class="modal-title" id="staticBackdropLabel">Agreement</h5>

                 </div>
                 <div class="modal-body">

                   


                         <form #form="ngForm">

                         <div class="form-check">
                             <input class="form-check-input" name="policy" type="checkbox" ngModel  value="" id="flexCheckDefault" required>
                        
                             <label class="form-check-label" for="flexCheckDefault">
                                 I/We agree to the <a href="https://getgrowthcapital.co/terms" target="_blank"
                                 style="color: #349999;font-weight: bold;">Terms & Conditions</a> and <a
                                 href="https://getgrowthcapital.co/privacy-policy" target="_blank"
                                 style="color: #349999;font-weight: bold;">Privacy Policy</a>
                             </label>
                           </div>
                           <div class="form-check">
                             <input class="form-check-input" name="consent" type="checkbox" ngModel value="" id="flexCheckChecked" required>
                             <label class="form-check-label text-justify" for="flexCheckChecked" >
                                 I/We hereby give my/our consent to GetGrowth Capital Private Limited to procure/fetch my/our AADHAAR details, PAN No/copy of my/our PAN Card, other identity proofs and Bank Account details from time to time, exchange, part with/share all information relating to my/our loan details and repayment history with other banks/financial institutions/Bureaus/CERSAI/CKYC etc. and periodically obtain/generate Bureau reports and such other reports as may be required from time to time, and shall not hold GetGrowth liable for use of this information.
                             </label>
                           </div>

                           <div class="modal-footer text-center">
                             <button type="button" class="btn btn-green" data-bs-dismiss="modal"
                                 (click)="updateTnc(true)" [disabled] = '!form.valid' >Proceed</button>
                         </div>

                     </form>
                 </div>



               

                 
             </div>
         </div>
     </div> -->
         
         
         
         
         
         
         
         
         
            <div class="ms-md-auto d-none d-lg-block b-md-block  d-flex align-items-center">
                <button class="btn btnhelp" (click)=" query.queryToggle.next(true) ">Need Help?</button>
            </div>
            <div class="ms-md-auto d-none d-lg-block b-md-block  d-flex align-items-center">
                <button class="btn btnhelp" routerLink="/main/brand-feedback">Feedback <i class="far fa-comments"
                        aria-hidden="true"></i></button>
            </div>


            <ul class="header_notification_warp d-flex align-items-center pl-0 mb-0">


                <li class="nav-item px-1 px-md-2 px-lg-2 d-none d-lg-block d-md-block d-flex align-items-center">
                    <a routerLink="/main/my-profile" class="nav-link text-body font-weight-bold px-0 iconbg">
                        <i class="far fa-user"></i>

                    </a>
                </li>


                <li class="nav-item px-1 px-md-2 px-lg-2 d-flex align-items-center d-block d-lg-none d-md-none">
                    <a (click)=" query.queryToggle.next(true) " class="nav-link text-body font-weight-bold px-0 iconbg">
                        <i class="fas fa-question"></i>

                    </a>
                </li>
                <li class="nav-item px-1 px-md-2 px-lg-2 d-flex align-items-center d-block d-lg-none d-md-none">
                    <a routerLink="/main/brand-feedback" class="nav-link text-body font-weight-bold px-0 iconbg">
                        <i class="fas fa-comments"></i>

                    </a>
                </li>

                <!-- <li class="nav-item px-1 px-md-2 px-lg-2 d-flex align-items-center">
                    <a href="javascript:;" class="nav-link text-body font-weight-bold px-0 iconbg" (click)="activityToggle = !activityToggle">
                        <i class="fa fa-bell cursor-pointer" aria-hidden="true"></i>
                    </a>
                </li> -->
                <li class="nav-item px-1 px-md-2 px-lg-2 d-flex align-items-center">
                    <a (click)="logout()" class="cursor nav-link text-body font-weight-bold px-0 iconbg">
                        <i class="fal fa-sign-out"></i>

                    </a>
                </li>
            </ul>

        </div>
    </div>
</nav>

<app-account-activity [activityToggle]="activityToggle" (sendData)="getUpdatedValueActivity($event)">
</app-account-activity>