import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Commons } from 'src/app/utils/commons';
import { QueryService } from "../../../services/query.service";

@Component({
  selector: 'app-raise-query',
  templateUrl: './raise-query.component.html',
  styleUrls: ['./raise-query.component.scss']
})
export class RaiseQueryComponent implements OnInit {

  queryToggle: boolean= false
  selectedQueryType: string = ''
  queryTypes: string[] = [
    'Grievance',
    'Ad and Digital Accounts',
    'Bank Accounts',
    'GST',
    'PG Accounts',
    'ITR Linking',
    'Entity Details',
    'Company Details',
    'Assets & Borrowings',
    'Financial Statements',
    'Annual Reports',
    'Company Documents',
    'KYC Documents',
    'Payments',
    'Vendors',
    'Disbursement',
    'Collections',
    'PG Accounts Activation/De-Activation',
    'Ad and Digital Accounts Activation/Deactivation',
    'Agreement',
    'Profile Details',
    'Other'
  ]
  queryMessage: string = ''
  applicationId: string = ''

  constructor( private queryService: QueryService, private util: Commons, private route: ActivatedRoute ) {
    this.route.queryParams.subscribe(data=>{
      this.applicationId = data.application
    })
  }

  ngOnInit(): void {
    this.queryService.queryToggle.subscribe(data=>{
      this.queryToggle = data
      this.queryToggle ? document.getElementById('raise').classList.add('active') : document.getElementById('raise').classList.remove('active')
    })
  }

  closeQuery(): void{ document.getElementById('raise').classList.remove('active') }

  raiseQuery(): void{
    this.util.startLoader()
    let payload = {
      queryMessage: this.queryMessage, 
      queryType: this.selectedQueryType
    }
    this.queryService.sendRaiseQueryRequest( payload ).subscribe(
      data=>{
        this.util.stopLoader()
        if( data.statusCode === "100" ){
          this.util.snackbar('Your query has been raised successfully. Our team will look forward to this and get back to you asap.'), this.queryMessage = '', this.selectedQueryType = ''
        }
        else this.util.errorPopup( data['msg'] || 'Failed to raise query!' ) 
      },
      error=>{
        this.util.stopLoader()
        this.util.errorPopup( error['error']['message'] || 'Failed to raise query!' )
      }
    )
    this.closeQuery();
  }

}
