import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../config';
import { AddVendor, VendorResponse } from '../interfaces/vendor.interface';
import { ADD_VENDOR, ALL_VENDORS, ALL_VENDORSDETAILS, ADD_VENDORDETAILS,UPLOADVNDERDOC } from './all.api';

const baseUrl = new Config().getBaseURL()+'/vendor';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  constructor(private http: HttpClient) { }

  fetchAllVendors$():Observable<VendorResponse>{
    return this.http.get<VendorResponse>( ALL_VENDORS )
  }
  fetchVendorsDetails$(companyId):Observable<VendorResponse>{
    return this.http.get<VendorResponse>( ALL_VENDORSDETAILS )
  }

  sendAddVendorRequest$( vendor: AddVendor ):Observable<VendorResponse>{
    return this.http.post<VendorResponse>( ADD_VENDOR , vendor )
  }

  ADD_VENDORDETAILS$( payload ):Observable<VendorResponse>{
    return this.http.post<VendorResponse>( ADD_VENDORDETAILS , payload )
  }

  uploadVendorDoc$( file:FormData ):Observable<VendorResponse>{
    return this.http.post<VendorResponse>( UPLOADVNDERDOC , file )
  }

}