import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, UpdateState } from '@ngxs/store';
import { FetchDealDetails } from '../actions/deal.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { ApplicationService } from "../../services/application.service";
import { ResetAllOnNewApplication } from '../actions/company.action';
import { DealDataResponse } from 'src/app/interfaces/deal.interface';
import { HttpErrorResponse } from '@angular/common/http';

export class DealsStateModel {
    isDealDetailsLoaded: boolean

    dealData: any
}

@State<DealsStateModel>({
  name: 'dealsState',
  defaults: {
    isDealDetailsLoaded: false,
    
    dealData: []
  },
})
@Injectable()
export class DealsState {
  constructor( private appService: ApplicationService, private util: Commons ) {}

  /* Selector to get selected application */
  @Selector()
  static getDeals(state: DealsStateModel) {
    return state.dealData
  }

  @Selector()
  static isDealsLoaded(state: DealsStateModel) {
    return state.isDealDetailsLoaded
  }


  @Action(FetchDealDetails)
  fetchDealDetails({getState, setState}: StateContext<FetchDealDetails>, {id}: FetchDealDetails){
    const state = getState()

    return this.appService.getDealDetails$( id ).pipe(tap(
        (res: DealDataResponse)=>{
            ( res.statusCode === "100" )
            && this.util.updateState( state, { isDealDetailsLoaded: true, dealData: res.data }, setState )
            || this.util.updateState( state, {
                isDealDetailsLoaded: false, 
                dealData: {
                    feeRate: 0,
                    fundIssued: 0,
                    monthlyLimit: 0,
                    moratoriumEndDate: 0,
                    moratoriumPeriod: 0,
                    revShare: 0,
                    absoluteFeeExcTax: 0
                  } 
                }, setState )
        },
        (error: HttpErrorResponse)=> this.util.updateState( state, {
            isDealDetailsLoaded: false, 
            dealData: {
                  feeRate: 0,
                  fundIssued: 0,
                  monthlyLimit: 0,
                  moratoriumEndDate: 0,
                  moratoriumPeriod: 0,
                  revShare: 0,
                  absoluteFeeExcTax: 0
                } 
          }, setState )
    ))

  }

  @Action(ResetAllOnNewApplication)
  resetDeal({getState, setState}: StateContext<ResetAllOnNewApplication>){
    const state = getState()
    this.util.updateState( state, { isDealDetailsLoaded: false, dealData: [] }, setState )
  }

}
