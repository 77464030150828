export class FetchCompanyEntityDetails{
    static readonly type = '[FETCH ENTITY DETAILS] GET'
}
export class FetchAllPGAccounts{
    static readonly type = '[FETCH PG ACCOUNTS] GET'
}
export class FetchAllBankAccounts{
    static readonly type = '[FETCH BANK ACCOUNTS] GET'
}
export class FetchAllITR{
    static readonly type = '[FETCH ITR] GET'
}
export class FetchAllGSTINS{
    static readonly type = '[FETCH ALL GSTINS] GET'
    constructor( public payload: any ){}
}
export class FetchAllMarketingAccounts{
    static readonly type = '[FETCH MARKETING ACCOUNTS] GET'
}

export class FetchMarketPlaceAccounts{
    static readonly type = '[FETCH MARKETPLACE ACCOUNTS] GET'
}
export class ResetAllOnNewApplication{
    static readonly type = '[RESET ALL ON NEW APPLICATION] RESET'
}
export class FetchAllAssets{
    static readonly type = '[FETCH ALL ASSETS] GET'
}
export class FetchAllObligations{
    static readonly type = '[FETCH ALL OBLIGATIONS] GET'
}
export class FetchAnnualDoc{
    static readonly type = '[FETCH ANNUAL DOC] GET'
    constructor(public type: string){}
}
export class FetchAllAdsAccounts{
    static readonly type = '[FETCH ALL ADS ACCOUNTS] GET'
}
export class FetchAllOtherDigitalSpends{
    static readonly type = '[FETCH ALL OTHER DIGITAL SPENDS] GET'
}
export class FetchFormStepStatus{
    static readonly type = '[FETCH FORM STEP STATUS] GET'
}
export class FetchNdaDetails{
    static readonly type = '[FETCH NDA DETAILS] GET'
}
export class PatchStatementsForSelectedAccount{
    static readonly type = '[PATCH STATEMENTS FOR SELECTED ACCOUNT] PATCH'
    constructor( public statements: any[], public selectedAccount: any ){}
}
export class PatchStepStatus{
    static readonly type = '[PATCH STEP STATUS] PATCH'
    constructor( public type: string, public subtype: string ){}
}
export class FetchShopifyAccounts{
    static readonly type = '[FETCH SHOPIFY ACCOUNTS] FETCH'
}