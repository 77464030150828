<div class="main pt-30 d-lg-block " id="query">

    <div class="d-flex align-items-center mb-30">
        <img width="20px" (click)="sendCloseVal()" class="cursor mr-15" src="../../../../../assets/img/icons/icon-(32).png" alt="back">
        <h5>Account Activity</h5>
    </div>

    <div class="white-bg notification mb-15">

        <div class="name-date d-flex justify-content-between align-items-center mb-10">
            <p>New Vendor Added</p>
            <p>Today, 12:00PM</p>
        </div>
        <p>
            New Vendor AA CYBER PLUS ADS has been added successfully.
        </p>

    </div>

</div>