<div class="pagination">
    <div class="all">
        <div class="left">
            <button [disabled]="currentPage <= 1" class="ripple btn primary-btn" (click)="decrementPage()">
                <mat-icon aria-hidden="false" aria-label="keyboard_arrow_left">keyboard_arrow_left</mat-icon>
            </button>
        </div>
        <div class="count">
            {{ currentPage }} / {{ totalPageCount }}
        </div>
        <div class="right">
            <button [disabled]="currentPage >= totalPageCount" class="ripple btn primary-btn" (click)="incrementPage()">
                <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right">keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
</div>