import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../config';
import {
  ALL_PAYMENTS,
  GET_VENDORSLIST,
  GET_DRAWDOWNREQUES_DETAILS,
  ADD_DRAWDOWNREQUEST,
  GET_BUYERLIST,
  UPLOAD_INVOICEANDGRNFILE,
  DRAWDOWN_REQUESTLIST,
  GET_DRAWDOWNREQUEST_LISTS,
  ALL_APPLICATIONS

} from './all.api';

const baseUrl = new Config().getBaseURL() + '/payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  fetchAllPayments(): Observable<any> {
    return this.http.get(ALL_PAYMENTS);
  }

  fetchAllApplication(): Observable<any> {
    return this.http.get(ALL_APPLICATIONS)
  }

  getVendorList(applicationId): Observable<any> {
    return this.http.post(GET_VENDORSLIST, applicationId);
  }
  drawdownRequestList(payload?: object): Observable<any> {
    return this.http.post(DRAWDOWN_REQUESTLIST, payload);
  }
  getDrawdownRequestDetails$(applicationId): Observable<any> {
    return this.http.post(GET_DRAWDOWNREQUES_DETAILS, applicationId);
  }
  addDrawdownRequest(payload): Observable<any> {
    return this.http.post(ADD_DRAWDOWNREQUEST, payload);
  }
  getBuyerList(applicationId): Observable<any> {
    return this.http.post(GET_BUYERLIST, applicationId);
  }
  getDrawdownRequestLists(): Observable<any> {
    return this.http.get(GET_DRAWDOWNREQUEST_LISTS);
  }
  uploadInvoiceAndGRNFile(file:FormData, applicationId): Observable<any> {
    return this.http.post(UPLOAD_INVOICEANDGRNFILE, file ,{ params: { applicationId } });
  }
  
  checkValidityOfVendorApprovalRequest(drawdownRequestId,token){
    return this.http.get(`${baseUrl}/checkValidityOfVendorApprovalRequest/${drawdownRequestId}/${token}`)
  }
  
  checkAndUpdateVendorApprovalStatus(payload): Observable<any> {
    return this.http.post(`${baseUrl}/checkAndUpdateVendorApprovalStatus`, payload);
  }
  
}
