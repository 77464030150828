import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchFormStepStatus, PatchStepStatus } from '../actions/company.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { FormSteps } from "../../interfaces/form-steps.interface";
import { CompanyService } from "../../services/company.service";

export class FormStepStateModel {
    isFormStepStateLoaded:  boolean
    formStepStatuses:       FormSteps
}

@State<FormStepStateModel>({
    name: 'formStepState',
    defaults: {
        isFormStepStateLoaded:  false,
        
        formStepStatuses:       null,
    },
})
@Injectable()
export class FormStepState {
    constructor( private util: Commons, private company: CompanyService ) {}

    @Selector()
    static getFormStepStatus(state: FormStepStateModel) {
        return state.formStepStatuses
    }

    @Selector()
    static isStepStatusLoaded(state: FormStepStateModel) {
        return state.isFormStepStateLoaded
    }


    @Action(FetchFormStepStatus)
    fetchFormStepsStatus({getState, setState}: StateContext<FetchFormStepStatus>){
        const state = getState()

        return this.company.getFormStepsStatus$().pipe(tap(
            res=>{
                ( res.statusCode === "100" )
                && this.util.updateState( state, { isFormStepStateLoaded: true, formStepStatuses: res.data }, setState )
                || this.util.updateState( state, { isFormStepStateLoaded: false, formStepStatuses: null }, setState )
            },
            error=> this.util.updateState( state, { isFormStepStateLoaded: false, formStepStatuses: null }, setState )
        ))
    }

    @Action(PatchStepStatus)
    patchStatusToStep({getState, setState}: StateContext<PatchStepStatus>, {type, subtype}: PatchStepStatus){
        const state = getState()

        let stepStatuses = JSON.parse(JSON.stringify(state['formStepStatuses']))
        stepStatuses[type][subtype] = true
        return this.util.updateState( state, { isFormStepStateLoaded: false }, setState )

    }

}
