import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from '../../services/company.service';
import { FetchAnnualDoc } from '../actions/company.action';
import { AnnualDocuments } from 'src/app/interfaces/annualReport.interface';

export class AnnualDocStateModel {
  isMISLoaded:                boolean;
  isShareholdingLoaded:       boolean;
  isPitchDeckLoaded:          boolean;
  isFinancialLoaded:          boolean;
  isKYCDocumentsLoaded:       boolean;
  isLegalDocumentsLoaded:     boolean;
  isFinancialDocumentsLoaded: boolean;

  mis:                AnnualDocuments[];
  shareholding:       AnnualDocuments[];
  pitchDeck:          AnnualDocuments[];
  financial:          AnnualDocuments[];
  kycDocuments:       AnnualDocuments[];
  legalDocuments:     AnnualDocuments[];
  financialDocuments: AnnualDocuments[];
}

@State<AnnualDocStateModel>({
  name: 'annualDocState',
  defaults: {
    isMISLoaded: false,
    isShareholdingLoaded: false,
    isPitchDeckLoaded: false,
    isFinancialLoaded: false,
    isKYCDocumentsLoaded: false,
    isLegalDocumentsLoaded: false,
    isFinancialDocumentsLoaded: false,

    mis: [],
    shareholding: [],
    pitchDeck: [],
    financial: [],
    kycDocuments: [],
    legalDocuments: [],
    financialDocuments: []
  },
})
@Injectable()
export class AnnualDocState {
  constructor(private util: Commons, private compService: CompanyService) {}

  /* Selector to get selected application */
  @Selector()
  static getMIS(state: AnnualDocStateModel) {
    return state.mis;
  }

  @Selector()
  static isMISLoaded(state: AnnualDocStateModel) {
    return state.isMISLoaded;
  }

  @Selector()
  static isPitchDeckLoaded(state: AnnualDocStateModel) {
    return state.isPitchDeckLoaded;
  }

  @Selector()
  static isShareholdingLoaded(state: AnnualDocStateModel) {
    return state.isShareholdingLoaded;
  }

  @Selector()
  static isFinancialLoaded(state: AnnualDocStateModel) {
    return state.isFinancialLoaded;
  }

  @Selector()
  static isKYCDocumentsLoaded(state: AnnualDocStateModel) {
    return state.isKYCDocumentsLoaded;
  }

  @Selector()
  static isFinancialDocumentsLoaded(state: AnnualDocStateModel) {
    return state.isFinancialLoaded;
  }

  @Selector()
  static isLegalDocumentsLoaded(state: AnnualDocStateModel) {
    return state.isKYCDocumentsLoaded;
  }

  @Selector()
  static getShareholding(state: AnnualDocStateModel) {
    return state.shareholding;
  }

  @Selector()
  static getPitchDeck(state: AnnualDocStateModel) {
    return state.pitchDeck;
  }

  @Selector()
  static getFinancial(state: AnnualDocStateModel) {
    return state.financial;
  }

  @Selector()
  static getKYCDocuments(state: AnnualDocStateModel) {
    return state.kycDocuments;
  }

  @Selector()
  static getFinancialDocuments(state: AnnualDocStateModel) {
    return state.financialDocuments;
  }

  @Selector()
  static getLegalDocuments(state: AnnualDocStateModel) {
    return state.legalDocuments;
  }

  @Action(FetchAnnualDoc)
  fetchAnnualDoc(
    { getState, setState }: StateContext<FetchAnnualDoc>,
    { type }: FetchAnnualDoc
  ) {
    const state = getState();

    return this.compService.getAnnualDocumentsByType$(type).pipe(
      tap(
        (res) => {
          if (type === 'mis') {
            (res['statusCode'] == '100' &&
              this.setMISSuccess(state, setState, res)) ||
              this.setMISFailure(state, setState);
          } else if (type === 'share_holding') {
            (res['statusCode'] == '100' &&
              this.setShareholdingSuccess(state, setState, res)) ||
              this.setShareholdingFailure(state, setState);
          } else if (type === 'pitch_deck') {
            (res['statusCode'] == '100' &&
              this.setPitchDeckSuccess(state, setState, res)) ||
              this.setPitchDeckFailure(state, setState);
          } else if (type === 'financial_statement') {
            (res['statusCode'] == '100' &&
              this.setFinancialSuccess(state, setState, res)) ||
              this.setFinancialFailure(state, setState);
          }
          else if (type === 'kyc') {
            (res['statusCode'] === '100' &&
              this.setKYCSuccess(state, setState, res)) ||
              this.setKYCFailure(state, setState);
          }
          else if (type === 'legal_document') {
            (res['statusCode'] === '100' &&
              this.setLegalDocumentsSuccess(state, setState, res)) ||
              this.setLegalDocumentsFailure(state, setState);
          }
          else if (type === 'financial_document') {
            (res['statusCode'] === '100' &&
              this.setFinancialDocumentsSuccess(state, setState, res)) ||
              this.setFinancialDocumentsFailure(state, setState);
          }
        },
        (error) =>{
          if (type === 'mis') {
            this.setMISFailure(state, setState);
          } else if (type === 'share_holding') {
            this.setShareholdingFailure(state, setState);
          } else if (type === 'pitch_deck') {
            this.setPitchDeckFailure(state, setState);
          } else if (type === 'financial_statement') {
            this.setFinancialFailure(state, setState);
          } else if (type === 'kyc') {
            this.setKYCFailure(state, setState);
          } else if (type === 'legal_document') {
            this.setLegalDocumentsFailure(state, setState);
          } else if (type === 'financial_document') {
            this.setFinancialDocumentsFailure(state, setState);
          }
        }
      )
    );

  }

  //===============mis helpers=====================
  setMISSuccess(state, setState, res) {
    return this.util.updateState(
      state,
      { isMISLoaded: true, mis: res['data']['documents'] },
      setState
    );
  }

  setMISFailure(state, setState) {
    return this.util.updateState(
      state,
      { isMISLoaded: false, mis: [] },
      setState
    );
  }
  //===============mis helpers end=====================

  //===============share holding helpers=====================
  setShareholdingSuccess(state, setState, res) {
    return this.util.updateState(
      state,
      { isShareholdingLoaded: true, shareholding: res['data']['documents'] },
      setState
    );
  }

  setShareholdingFailure(state, setState) {
    return this.util.updateState(
      state,
      { isShareholdingLoaded: false, shareholding: [] },
      setState
    );
  }
  //===============share holding helpers end=====================

  //===============pitch deck helpers=====================
  setPitchDeckSuccess(state, setState, res) {
    return this.util.updateState(
      state,
      { isPitchDeckLoaded: true, pitchDeck: res['data']['documents'] },
      setState
    );
  }

  setPitchDeckFailure(state, setState) {
    return this.util.updateState(
      state,
      { isPitchDeckLoaded: false, pitchDeck: [] },
      setState
    );
  }
  //===============pitch deck helpers end=====================

  //===============financial helpers=====================
  setFinancialSuccess(state, setState, res) {
    return this.util.updateState(
      state,
      { isFinancialLoaded: true, financial: res['data']['documents'] },
      setState
    );
  }

  setFinancialFailure(state, setState) {
    return this.util.updateState(
      state,
      { isFinancialLoaded: false, financial: [] },
      setState
    );
  }
  //===============financial helpers end=====================

  //===============financial helpers=====================
  setKYCSuccess(state, setState, res) {
    return this.util.updateState(
      state,
      { isKYCDocumentsLoaded: true, kycDocuments: res['data']['documents'] },
      setState
    );
  }

  setKYCFailure(state, setState) {
    return this.util.updateState(
      state,
      { isKYCDocumentsLoaded: false, kycDocuments: [] },
      setState
    );
  }
  //===============financial helpers end=====================

  //===============legal documents helpers=====================
  setLegalDocumentsSuccess(state, setState, res) {
    return this.util.updateState(
      state,
      { isLegalDocumentsLoaded: true, legalDocuments: res['data']['documents'] },
      setState
    );
  }

  setLegalDocumentsFailure(state, setState) {
    return this.util.updateState(
      state,
      { isLegalDocumentsLoaded: false, legalDocuments: [] },
      setState
    );
  }
  //===============legal documents helpers end=====================

  
  //===============legal documents helpers=====================
  setFinancialDocumentsSuccess(state, setState, res) {
    return this.util.updateState(
      state,
      { isFinancialDocumentsLoaded: true, financialDocuments: res['data']['documents'] },
      setState
    );
  }

  setFinancialDocumentsFailure(state, setState) {
    return this.util.updateState(
      state,
      { isFinancialDocumentsLoaded: false, financialDocuments: [] },
      setState
    );
  }
  //===============legal documents helpers end=====================

}
