import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { FetchAllAssets } from '../actions/company.action';
import { Asset } from 'src/app/interfaces/assets.interface';

export class AssetsStateModel {
    isAssetsLoaded: boolean

    assets:         Asset[]
}

@State<AssetsStateModel>({
  name: 'assetState',
  defaults: {
    isAssetsLoaded: false,
    
    assets: []
  },
})
@Injectable()
export class AssetsState {
  constructor( private util: Commons, private compService: CompanyService ) {}

  /* Selector to get selected application */
  @Selector()
  static getAssets(state: AssetsStateModel) {
    return state.assets
  }

  @Selector()
  static isAssetsLoaded(state: AssetsStateModel) {
    return state.isAssetsLoaded
  }


  @Action(FetchAllAssets)
  fetchAssets({getState, setState}: StateContext<FetchAllAssets>){
    const state = getState()

    return this.compService.getAllAssets$().pipe(tap(
        res=>{
            ( res.statusCode === "100" )
            && this.util.updateState( state, { isAssetsLoaded: true, assets: res['data'] }, setState )
            || this.util.updateState( state, { isAssetsLoaded: false, assets: [] }, setState )
        },
        error=> this.util.updateState( state, { isAssetsLoaded: false, assets: [] }, setState )
    ))

  }

}
