import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { CompanyService } from '../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class Commons {

  emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  namePattern = /^[a-zA-Z0-9 ]*$/
  panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
  phoneNoPattern = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
  accountNoPattern = /^[a-zA-Z0-9]*$/
  amountPattern = /^\d+(\.\d{1,2})?$/
  ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/

  constructor(
    private datepipe: DatePipe,
    private _snackbar: MatSnackBar,
    private loader: NgxUiLoaderService,
    private company: CompanyService
  ) {}

  public transformDate(date, format) {
    return this.datepipe.transform(date, format);
  }

  public snackbar(message) {
    return this._snackbar.open(message, 'OK', { duration: 3000 });
  }

  public startLoader() {
    this.loader.start();
  }

  public stopLoader() {
    this.loader.stop();
  }

  /* =========================================swal popup start===================================== */
  // warning function
  warningPopup = (message?: string) => {
    Swal.fire({
      icon: 'warning',
      title: 'Warning!',
      text: message,
      confirmButtonText: 'OK',
    });
  };

  successPopup = (message?: string) => {
    Swal.fire({
      title: 'Success!',
      text: `${message}`,
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  errorPopup = (message?: string) => {
    Swal.fire({
      title: 'Error!',
      text: `${message || 'Something went wrong!'}`,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  };
  /* =========================================swal popup end===================================== */

  /* =========================================input validations start===================================== */
  phoneNumberValidation = (input, id) => {
    ///get id with value; ////Regular expression
    (this.phoneNoPattern.test(input) &&
      (document.getElementById(id).style.background = 'white')) ||
      (document.getElementById(id).style.background = '#ffcfcf');

    return of(!this.phoneNoPattern.test(input))
  };

  emailValidation = (input, id) => {
    ///get id with value
    (this.emailPattern.test(input) &&
      (document.getElementById(id).style.background = 'white')) ||
      (document.getElementById(id).style.background = '#ffcfcf');

    return of(!this.emailPattern.test(input))
  };

  namesValidation = (input, id) => {
    ///get id with value
    (this.namePattern.test(input) &&
      (document.getElementById(id).style.background = 'white')) ||
      (document.getElementById(id).style.background = '#ffcfcf');

    return of(!this.namePattern.test(input))
  };

  accountNoValidation = (input, id) => {
    ///get id with value
    (this.accountNoPattern.test(input) &&
      (document.getElementById(id).style.background = 'white')) ||
      (document.getElementById(id).style.background = '#ffcfcf');
    
      return of(!this.accountNoPattern.test(input))
  };

  amountValidation = (input, id) => {
    ///get id with value
    (this.amountPattern.test(input) &&
      (document.getElementById(id).style.background = 'white')) ||
      (document.getElementById(id).style.background = '#ffcfcf');

    return of(!this.amountPattern.test(input))
  };

  ifscValidation = (input, id) => {
    ///get id with value
    (this.ifscPattern.test(input) &&
      (document.getElementById(id).style.background = 'white')) ||
      (document.getElementById(id).style.background = '#ffcfcf');

    return of(!this.ifscPattern.test(input))
  };

  panValidation = (input, id) => {
    ///get id with value
    (this.panPattern.test(input) &&
      (document.getElementById(id).style.background = 'white')) ||
      (document.getElementById(id).style.background = '#ffcfcf');

    return of(!this.panPattern.test(input))
  };

  /* =========================================swal popup end===================================== */

  updateState = (oldObject, updatedProperties, setState) => {
    return setState({
      ...oldObject,
      ...updatedProperties,
    });
  };

  validateIfsc( ifsc: string ){
    this.startLoader()
    if( !ifsc ){ 
      this.stopLoader()
      return { isIfscValid: false, bankName: null, branchName: null }
    }
    this.company.validateIFSC$( ifsc ).subscribe(
      (res) => {
        this.stopLoader()
        if (res.statusCode === '100' && res.data.ifscValidate) {
          let isIfscValid = true,
          {BANK, BRANCH} = res.data.bankDetails;
          return{ isIfscValid, bankName: BANK, branchName: BRANCH }
        }
        else { 
          return { isIfscValid: false, bankName: null, branchName: null } 
        }
      },
      (err: HttpErrorResponse) => { 
        this.stopLoader()
        return { isIfscValid: false, bankName: null, branchName: null }
      }
    );
  }

}
