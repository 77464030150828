import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ChildAuthGuard implements CanActivateChild {

  constructor( private router: Router, private auth: AuthService ){}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    return this.auth
      .isLoggedIn()
      .pipe(
        map(
          (isLoggedIn) =>
            isLoggedIn ||
            this.router.createUrlTree(['/auth/login'], {
              queryParams: { returnUrl: state.url },
            })
        )
      );
  }
}
