import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  arrayOfColumns = []
  arrayOfRows = ['','','','','']
  @Input() noOfColumns: number = 3

  constructor() { }

  ngOnInit(): void {

    for( let i= 0; i< this.noOfColumns; i++ ){
      this.arrayOfColumns.push('')
    }

  }

}
