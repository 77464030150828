import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { FetchFundUtilizationData } from "../../store/actions/application.action";
import { ApplicationService } from "../../services/application.service";
import { FundUtilizationResponse } from 'src/app/interfaces/fundUtilization.interface';
import { HttpErrorResponse } from '@angular/common/http';

export class FundsUtilizationStateModel {
    isUtilizationLoaded: boolean

    fundUtilization: any
}

@State<FundsUtilizationStateModel>({
  name: 'fundUtilizationState',
  defaults: {
    isUtilizationLoaded: false,
    
    fundUtilization: null
  },
})
@Injectable()
export class FundUtilizationState {
  constructor( private util: Commons, private appService: ApplicationService ) {}

  /* Selector to get selected application */
  @Selector()
  static getData(state: FundsUtilizationStateModel) {
    return state.fundUtilization
  }

  @Selector()
  static isFundUtilizationDataLoaded(state: FundsUtilizationStateModel) {
    return state.isUtilizationLoaded
  }


  @Action(FetchFundUtilizationData)
  fetchFundUtilization({getState, setState}: StateContext<FetchFundUtilizationData>, {id}:FetchFundUtilizationData){
    const state = getState()

    return this.appService.getFundUtilizationData$( id ).pipe(tap(
        (res: FundUtilizationResponse)=>{
            ( res.statusCode == "100" )
            && this.util.updateState( state, { isUtilizationLoaded: true, fundUtilization: res.data }, setState )
            || this.util.updateState( state, { 
              isUtilizationLoaded: false, 
              fundUtilization: {
                feeRepaid: 0,
                feeRepaidWidth: 0,
                fundRemaining: 0,
                fundUtilised: 0,
                fundsUtilisedWidth: 0,
                principalRepaid: 0,
                principalRepaidWidth: 0
              }
            }, setState )
        },
        (error: HttpErrorResponse)=> this.util.updateState( state, { 
          isUtilizationLoaded: false, 
          fundUtilization: {
            feeRepaid: 0,
            feeRepaidWidth: 0,
            fundRemaining: 0,
            fundUtilised: 0,
            fundsUtilisedWidth: 0,
            principalRepaid: 0,
            principalRepaidWidth: 0
          } 
        }, setState )
    ))

  }

  resetFundData(){
    return {
      fundUtilization: {
      feeRepaid: 0,
      feeRepaidWidth: 0,
      fundRemaining: 0,
      fundUtilised: 0,
      fundsUtilisedWidth: 0,
      principalRepaid: 0,
      principalRepaidWidth: 0
    }}
  }

}
