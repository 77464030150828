import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-account-activity',
  templateUrl: './account-activity.component.html',
  styleUrls: ['./account-activity.component.scss']
})
export class AccountActivityComponent implements OnInit, OnChanges {

  @Input() activityToggle: boolean= false
  @Output() sendData= new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.activityToggle){
      document.getElementById('query').classList.add('active')
    }
    else document.getElementById('query').classList.remove('active')
  }

  sendCloseVal() {
    this.sendData.emit(false);
  }
}
