import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../config';
import { RAISE_QUERY } from './all.api'

const baseUrl = new Config().getBaseURL()+'/userQuery';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  queryToggle = new BehaviorSubject<boolean>(false)

  constructor(private http: HttpClient) { }

  sendRaiseQueryRequest( data ):Observable<any>{
    return this.http.post( RAISE_QUERY , data )
  }

}