import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../config';
import {
  ApplicationResponse,
  CreateApplication,
} from '../interfaces/application.interface';
import { DealDataResponse } from '../interfaces/deal.interface';
import { FundUtilizationResponse } from '../interfaces/fundUtilization.interface';
import {
  RecoveryResponse,
  SpendsResponse,
  EscrowResponse,
} from '../interfaces/spends-recovery.interface';
import {
  ALL_APPLICATIONS,
  CREATE_APPLICATION,
  SUBMIT_APPLICATION,
  DEAL_DATA,
  DISBURSEMENTS,
  RECOVERY_DATA,
  FUND_UTILIZATION,
  ESCROW_STATEMENT,
  GET_VA_STATEMENT,
  DOCUMENT_SIGNING_LINK,
  DOCUMENT_DOWNLOAD,
  ADD_PREAPPROVED_DEAL,
  DOWNLOAD_DOCUMENT,
  FETCH_INVESTING_PARTNERS,
  FETCH_DOCUMENT_DETAILS,
} from './all.api';

const baseUrl: string = new Config().getBaseURL();

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  isFirstApplication = new BehaviorSubject<boolean>(true);
  firstApplicationStatus = new BehaviorSubject<string>('inprocess');

  constructor(private http: HttpClient) {}

  /* =======================================GET APIS================================= */
  getAllApplications$(): Observable<ApplicationResponse> {
    return this.http.get<ApplicationResponse>(ALL_APPLICATIONS);
  }
  downloadDocuments(applicationId:string,queryParams:object){
    return this.http.get(DOWNLOAD_DOCUMENT(applicationId),{responseType: 'blob',params:{...queryParams}})
  }
  fetchInvestingPartners(id:string){
    return this.http.get(FETCH_INVESTING_PARTNERS(id))
  }
  fetchDocumentDetails(appId:string,queryParams:any){
    return this.http.get(FETCH_DOCUMENT_DETAILS(appId),{params:queryParams})
  }
  getDealDetails$(id: string): Observable<DealDataResponse> {
    return this.http.get<DealDataResponse>(DEAL_DATA(id));
  }
  getAllSpends$({ id, page, count }): Observable<SpendsResponse> {
    return this.http.get<SpendsResponse>(DISBURSEMENTS(id), {
      params: { page, count },
    });
  }

  getAllCollectionData$({ id, page, count, d }): Observable<RecoveryResponse> {
    return this.http.get<RecoveryResponse>(RECOVERY_DATA(id), {
      params: { page, count, d },
    });
  }

  getFundUtilizationData$(id: string): Observable<FundUtilizationResponse> {
    return this.http.get<FundUtilizationResponse>(FUND_UTILIZATION(id));
  }

  getEscrowStatementData$({ id, page, count, d }): Observable<EscrowResponse> {
    return this.http.get<EscrowResponse>(ESCROW_STATEMENT(id), {
      params: { page, count, d },
    });
  }

  getVaStatementData$({ page, count, d }): Observable<EscrowResponse> {
    return this.http.get<EscrowResponse>(GET_VA_STATEMENT, {
      params: { page, count, d },
    });
  }

  submitFirstApplication$(partnerName: string, offerCode: string): Observable<any> {
    return this.http.get<any>(`${SUBMIT_APPLICATION}/${partnerName}/${offerCode}`, {});
  }

  /* ==========================================POST APIS================================== */
  createApplication$(): Observable<CreateApplication> {
    return this.http.post<CreateApplication>(CREATE_APPLICATION, {});
  }
  getInitialOffer$(url: string, data: any): Observable<any> {
    return this.http.post<any>(url, data);
  }
  updateStatus$(url: string, data: any): Observable<any> {
    return this.http.post<any>(url, data);
  }

  fetchDocumentSignLink$(id): Observable<EscrowResponse> {
    return this.http.get<EscrowResponse>(DOCUMENT_SIGNING_LINK(id));
  }
  fetchDocumentDownlaodLink$(id): Observable<EscrowResponse> {
    return this.http.get<EscrowResponse>(DOCUMENT_DOWNLOAD(id));
  }
  getKycOtp(payload: any) {
    return this.http.post(`${baseUrl}/kyc/aadhar-kyc`, payload);
  }
  submitKycOtp(payload: any) {
    return this.http.post(`${baseUrl}/kyc/aadhar-download`, payload);
  }
  userKycDetail(id: any) {
    return this.http.get(`${baseUrl}/kyc/kycUserDetails/${id}`);
  }
  getDealTerms(payload: any): Observable<DealDataResponse> {
    return this.http.post<DealDataResponse>(
      `${baseUrl}/creditAnalysis/dealTerms`,
      payload
    );
  }
  addWooCommerce(payload: any) {
    return this.http.post(`${baseUrl}/company/addWooCommerce`, payload);
  }
  // getWooCommerce() {
  //   return this.http.get(`${baseUrl}/company/getWooCommerce`);
  // }
  getWooCommerceAndShipRocket() {
    return this.http.get(`${baseUrl}/company/getAccountDetails`);
  }
  removeWooCommerce(id: any) {
    return this.http.get(`${baseUrl}/company/${id}/removeWooCommerce`);
  }
  addShipRocket(payload: any) {
    return this.http.post(`${baseUrl}/company/addShipRocket`, payload);
  }
  // updateShipRocket(payload: any) {
  //   return this.http.post(`${baseUrl}/company/updateShipRocket`, payload);
  // }
  removeShipRocket(id: any) {
    return this.http.get(`${baseUrl}/company/${id}/removeShipRocket`);
  }
  // getShipRocket() {
  //   return this.http.get(`${baseUrl}/company/getShipRocket`);
  // }

  createPreApprovedDeal$(): Observable<CreateApplication> {
    return this.http.get<CreateApplication>(ADD_PREAPPROVED_DEAL);
  }
}
