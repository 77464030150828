import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-google-oauth',
  templateUrl: './google-oauth.component.html',
  styleUrls: ['./google-oauth.component.scss']
})
export class GoogleOauthComponent implements OnInit {

  constructor( private company: CompanyService, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe( params => {

      if( params['code'] ){

        this.company.sendRequestToAddAdwordAccount$( params['code'] ).subscribe(
          res=>{
            if( res.statusCode === '100' ){
              this.router.navigate(['/main/new-application/marketing'], { relativeTo: this.route, queryParams: { googleAcc: 'Success' } });
            }
            else{
              this.router.navigate(['/main/new-application/marketing'], { relativeTo: this.route, queryParams: { err: 'No Ad Account' } })
            }
          },
          error=>{
            let message
            error.error.message === 'Not an Ad account!'
            && (message = 'No Ad Account')
            || (message = 'Something went wrong')
            
            this.router.navigate(['/main/new-application/marketing'], { relativeTo: this.route, 
              queryParams: { err: message } })
          }
        )

      }
      else{
        this.router.navigate(['/main/new-application/marketing'], { relativeTo: this.route, queryParams: { err: 'No Ad Account' } })
      }

    });
  }

}
