import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from 'src/app/services/auth.service';
import { QueryService } from 'src/app/services/query.service';
import { UserData, UserProfile } from 'src/app/interfaces/auth.interface';
import { Commons } from 'src/app/utils/commons';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from "rxjs/operators";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('openTermsDialog') openTermsDialog: ElementRef;
  status: boolean = false;
  queryToggle: boolean = false
  activityToggle: boolean = false
  applicationStatus: string = 'disbursed'
  heading: string = ''
  username: string = ''
  agreedTerms: boolean = false
  clientsBasicDetails: UserData
  ipAddress: string = '';
  clickEvent() {
    this.status = !this.status;
  }

  constructor(private router: Router, private auth: AuthService, private store: Store, public query: QueryService, private util: Commons, private http: HttpClient) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = window.location.href
        if (url.includes('dashboard')) this.heading = 'Dashboard'
        else if (url.includes('my-profile')) this.heading = 'My Profile'
        else if (url.includes('company-details')) this.heading = 'Company Details'
        else if (url.includes('vendors')) this.heading = 'Vendors'
        else if (url.includes('payments')) this.heading = 'Payments'
      }
    });
  }

  ngOnInit(): void {
    this.username = JSON.parse(localStorage.getItem('user'))?.name
    this.initializeUserData()
    this.getUserDetails()
  }

  logout() {
    this.auth.logout().subscribe(
      res => this.clearStateAndNavigate(),
      err => this.clearStateAndNavigate()
    );
  }

  clearStateAndNavigate() {
    localStorage.clear()
    this.store.reset(null)
    this.router.navigate(['/'])
  }

  getUpdatedValue(event) {
    this.queryToggle = event
  }

  getUpdatedValueActivity(event) {
    this.activityToggle = event
  }

  getUserDetails() {
    this.auth.fetchUserData().subscribe(
      (data: UserProfile) => {
        if (data.statusCode === '100') {
          this.clientsBasicDetails = data.data
          if (!this.clientsBasicDetails.company.termsAndConditions) {
            this.openTermsDialog.nativeElement.click();
          } else {
          }
        }
        else { this.initializeUserData() }
      },
      (error) => {
        if(error.error.message==='Token Unauthorized' && error.error.statusCode==='101'){
          this.logout()
        }
      }
    )
  }

  initializeUserData() {
    this.clientsBasicDetails = {
      user: {
        _id: '',
        name: '',
        designation: '',
        email: '',
        roles: '',
        isCompleted: false,
        isVerified: {
          email: false,
          contactNo: false
        },
        lastLoggedIn: '',
        lastLoggedOut: '',
        contactNo: ''
      },
      company: {
        _id: '',
        brandName: '',
        panName: '',
        category: '',
        businessName: '',
        websiteLink: '',
        termsAndConditions: false,
        termsAndConditionsTimestamp: ''
      },
      application: {
        _id: '',
        monthlyOnlineSale: 0,
        monthlyDigitalMarketingBudget: 0,
        marginPercentage: false,
        incrementInBudget: 0
      }
    }
  }

  updateTnc(termsvalue) {
    var params = {
      termsAndConditions: termsvalue
    }
    this.auth.agreeTnc(params).subscribe(data => {
      if (data.statusCode === '100') {
        this.util.snackbar('Thank for your response on terms. We really appreciate it.')
      } else {
        this.util.errorPopup(data['message'] || 'Failed to agree terms!')
      }
    }, error => {
      this.util.errorPopup(error['error']['message'] || 'Failed to agree terms!')
    })
  }



}
