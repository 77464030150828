import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchAllEscrows } from '../actions/application.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { ApplicationService } from '../../services/application.service';
import { EscrowResponse } from '../../interfaces/spends-recovery.interface';
import { HttpErrorResponse } from '@angular/common/http';

export class EscrowsStateModel {
  isAllEscrowsLoaded: boolean

  allEscrows: any[]
  totalEscrowsPages: number
}

@State<EscrowsStateModel>({
  name: 'escrowsState',
  defaults: {
    isAllEscrowsLoaded: false,

    allEscrows: [],
    totalEscrowsPages: 0
  },
})
@Injectable()
export class EscrowsState {
  constructor(private appService: ApplicationService, private util: Commons) { }

  /* Selector to get selected application */

  /* ==============================================card Escrows selector and action=================================== */

  @Selector()
  static getAllEscrows(state: EscrowsStateModel) {
    return {
      allEscrows: state.allEscrows,
      totalPages: state.totalEscrowsPages
    };
  }

  @Selector()
  static isAllEscrowsLoaded(state: EscrowsStateModel) {
    return state.isAllEscrowsLoaded
  }


  @Action(FetchAllEscrows)
  fetchAllEscrows({ getState, setState }: StateContext<FetchAllEscrows>, { payload }: FetchAllEscrows) {
    const state = getState()
    const { count } = payload
    return this.appService.getEscrowStatementData$(payload).pipe(tap(
      (res: EscrowResponse) => {

        (res.statusCode === "100")
          && this.util.updateState(state,
            {
              isAllEscrowsLoaded: true,
              allEscrows: res.data.paymentDetails,
              totalEscrowsPages: Math.ceil(res.data.totalCount / count)
            },
            setState)
          || this.util.updateState(state, { isAllEscrowsLoaded: false, allEscrows: [] }, setState)
      },
      (error: HttpErrorResponse) => this.util.updateState(state, { isAllEscrowsLoaded: false, allEscrows: [] }, setState)
    ))
  }

  /* ==============================================card Collections selector and action end=================================== */

}
