import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) { }

    canActivate(
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | boolean | UrlTree {

        return this.auth.isLoggedIn().pipe(
            map(isLoggedIn => isLoggedIn || this.router.createUrlTree(['/auth/login'], { queryParams: { returnUrl: state.url }}))
        )
    }

}