import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmountToLakhPipe } from './amount-to-lakh.pipe';
import { AmountToWordsPipe } from './amount-to-words.pipe';
import { DownloadDocumentDirective } from './download-document.directive';
import { IndianAmountFormatPipe } from './indian-amount-format.pipe';
@NgModule({
  declarations: [AmountToLakhPipe, AmountToWordsPipe, DownloadDocumentDirective, IndianAmountFormatPipe],
  exports: [ 
    DownloadDocumentDirective, 
    AmountToLakhPipe, 
    IndianAmountFormatPipe,
    AmountToWordsPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesAndDirectivesModule { }
