import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { FetchAllBankAccounts, FetchCompanyEntityDetails } from '../actions/company.action';

export class BankStateModel {
    isBankAccountsLoaded: boolean

    bankAccounts:         any
}

@State<BankStateModel>({
  name: 'bankState',
  defaults: {
    isBankAccountsLoaded: false,
    
    bankAccounts: []
  },
})
@Injectable()
export class BankState {
  constructor( private util: Commons, private compService: CompanyService ) {}

  /* Selector to get selected application */
  @Selector()
  static getBankAccounts(state: BankStateModel) {
    return state.bankAccounts
  }

  @Selector()
  static isBankAccountsLoaded(state: BankStateModel) {
    return state.isBankAccountsLoaded
  }


  @Action(FetchAllBankAccounts)
  fetchBankAccounts({getState, setState}: StateContext<FetchAllBankAccounts>){
    const state = getState()

    return this.compService.getAllBankAccounts().pipe(tap(
        res=>{
            ( res.statusCode == "100" )
            && this.util.updateState( state, { isBankAccountsLoaded: true, bankAccounts: res['data'] }, setState )
            || this.util.updateState( state, { isBankAccountsLoaded: false, bankAccounts: [] }, setState )
        },
        error=> this.util.updateState( state, { isBankAccountsLoaded: false, bankAccounts: [] }, setState )
    ))

  }

}
