import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { FetchAllMarketingAccounts, FetchAllOtherDigitalSpends, FetchShopifyAccounts } from '../actions/company.action';
import { MarketingAccounts  } from "../../interfaces/marketing.interface";
export class MarketingStateModel {
    isMarketingAccountsLoaded:  boolean
    isOtherDigitalSpendsLoaded: boolean
    isShopifyAccountsLoaded:    boolean

    googleAccounts:             MarketingAccounts[]
    facebookAccounts:           MarketingAccounts[]
    otherDigitalSpends:         any[]
    shopifyAccounts:            any[]
}

@State<MarketingStateModel>({
  name: 'marketingState',
  defaults: {
    isMarketingAccountsLoaded: false,
    isOtherDigitalSpendsLoaded: false,
    isShopifyAccountsLoaded: false,
    
    googleAccounts: [],
    facebookAccounts: [],
    otherDigitalSpends: [],
    shopifyAccounts: []
  },
})
@Injectable()
export class MarketingState {
  constructor( private util: Commons, private compService: CompanyService ) {}
  
  /* ===============================marketing accounts starts=============================== */
  @Selector()
  static marketingAccounts(state: MarketingStateModel) {
    return {
      googleAccounts: state.googleAccounts,
      facebookAccounts: state.facebookAccounts
    }
  }

  @Selector()
  static isMarketingAccountsLoaded(state: MarketingStateModel) {
    return state.isMarketingAccountsLoaded
  }

  @Action(FetchAllMarketingAccounts)
  marketingAccounts({getState, setState}: StateContext<FetchAllMarketingAccounts>){
    const state = getState()

    return this.compService.getAllAdsAccounts$().pipe(tap(
        res=>{
            ( res.statusCode == "100" )
            && this.util.updateState( 
              state, 
              { 
                isMarketingAccountsLoaded: true, 
                googleAccounts: res.data.filter(account=> account.accType === 'adwords'), 
                facebookAccounts: res.data.filter(account=> account.accType === 'facebook')
              }, 
                setState )
            || this.errorResponse( state, setState )
        },
        error=> this.errorResponse( state, setState )
    ))
  }
  /* ===============================marketing accounts ends=============================== */

  /* ===============================other digital spends starts=============================== */
  @Selector()
  static isOtherSpendsLoaded(state: MarketingStateModel) {
    return state.isOtherDigitalSpendsLoaded
  }

  @Selector()
  static otherDigitalSpends(state: MarketingStateModel) {
    return state.otherDigitalSpends
  }

  @Action(FetchAllOtherDigitalSpends)
  otherSpends({getState, setState}: StateContext<FetchAllOtherDigitalSpends>){
    const state = getState()

    return this.compService.getAllOtherDigitalSpends$().pipe(tap(
        res=>{
            ( res.statusCode == "100" )
            && this.util.updateState( state, { isOtherDigitalSpendsLoaded: true, otherDigitalSpends: res.data }, setState )
            || this.util.updateState( state, { isOtherDigitalSpendsLoaded: false, otherDigitalSpends: [] }, setState )
        },
        error=> this.util.updateState( state, { isOtherDigitalSpendsLoaded: false, otherDigitalSpends: [] }, setState )
    ))
  }
  /* ===============================other digital spends ends=============================== */


    /* ===============================shopify account starts=============================== */
    @Selector()
    static isShopifyAccountsLoaded(state: MarketingStateModel) {
      return state.isShopifyAccountsLoaded
    }
  
    @Selector()
    static shopifyAccounts(state: MarketingStateModel) {
      return state.shopifyAccounts
    }
  
    @Action(FetchShopifyAccounts)
    fetchShopify({getState, setState}: StateContext<MarketingStateModel>){
      const state = getState()
  
      return this.compService.fetchShopifyAccounts$().pipe(tap(
          res=>{
              ( res.statusCode === "100" )
              && this.util.updateState( state, { isShopifyAccountsLoaded: true, shopifyAccounts: res.data }, setState )
              || this.util.updateState( state, { isShopifyAccountsLoaded: false, shopifyAccounts: [] }, setState )
          },
          error=> this.util.updateState( state, { isShopifyAccountsLoaded: false, shopifyAccounts: [] }, setState )
      ))
    }
    /* ===============================shopify account ends=============================== */


  errorResponse( state, setState ){
    return this.util.updateState( 
      state, 
      { 
        isMarketingAccountsLoaded: false, 
        googleAccounts: [], 
        facebookAccounts: []
      }, 
      setState )
  }

}