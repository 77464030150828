import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'amountToLakh'
})
export class AmountToLakhPipe implements PipeTransform {

    transform(input: any, args?: any): any {
      return !input && ("₹0.00L") || ("₹"+ (input/ 100000).toFixed(args) + "L")
    }

}