import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { FetchAllITR } from '../actions/company.action';
import { ITR } from "../../interfaces/itr.interface";

export class ITRStateModel {
    isITRLoaded: boolean

    itrData:     ITR
}

@State<ITRStateModel>({
  name: 'itrState',
  defaults: {
    isITRLoaded:      false,
    
    itrData: {
        itUsername:   '',
        itrFileLink:  [],
      }
  },
})
@Injectable()
export class ItrState {
  constructor( private util: Commons, private compService: CompanyService ) {}

  /* Selector to get selected application */
  @Selector()
  static getItrData(state: ITRStateModel) {
    return state.itrData
  }

  @Selector()
  static isITRLoaded(state: ITRStateModel) {
    return state.isITRLoaded
  }

  @Action(FetchAllITR)
  fetchItrData({getState, setState}: StateContext<FetchAllITR>){
    const state = getState()

    return this.compService.getAllITR$().pipe(tap(
        res=>{
            ( res.statusCode === "100" )
            && this.util.updateState( state, { isITRLoaded: true, itrData: res.data }, setState )
            || this.util.updateState( state, { isITRLoaded: false, itrData: this.resetITR() }, setState )
        },
        error=> this.util.updateState( state, { isITRLoaded: false, itrData: this.resetITR() }, setState )
    ))

  }

  resetITR(){
    return {
      itrData : {
        itUsername: '',
        itrFileLink: [],
      }
    }
  }

}
