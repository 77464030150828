import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { FetchAllGSTINS } from '../actions/company.action';

export class GSTStateModel {
    isGSTINSLoaded: boolean

    gstins:         any[]
    totalPages:     number
}

@State<GSTStateModel>({
  name: 'gstinState',
  defaults: {
    isGSTINSLoaded: false,
    
    gstins: [],
    totalPages: 0
  },
})
@Injectable()
export class GSTState {
  constructor( private util: Commons, private compService: CompanyService ) {}

  /* Selector to get selected application */
  @Selector()
  static getGstins(state: GSTStateModel) {
    return {
      gstins: state.gstins,
      totalPages: state.totalPages
    }
  }

  @Selector()
  static isGSTINSLoaded(state: GSTStateModel) {
    return state.isGSTINSLoaded
  }


  @Action(FetchAllGSTINS)
  fetchGstins({getState, setState}: StateContext<GSTStateModel>, { payload }: FetchAllGSTINS) {
    const state = getState()
    const { page, count } = payload
    return this.compService.getAllGSTINS( page, count ).pipe(tap(
        res=>{
            ( res.statusCode == "100" )
            && this.util.updateState( state, { 
              isGSTINSLoaded: true, gstins: res['data']['gstin'], totalPages: Math.ceil(res['data']['totalGSTIN']/5)
            }, setState )
            || this.util.updateState( state, { isGSTINSLoaded: false, gstins: [], totalPages: 0 }, setState )
        },
        error=> this.util.updateState( state, { isGSTINSLoaded: false, gstins: [], totalPages: 0 }, setState )
    ))

  }

}
