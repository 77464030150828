import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-facebook-oauth',
  templateUrl: './facebook-oauth.component.html',
  styleUrls: ['./facebook-oauth.component.scss']
})
export class FacebookOauthComponent implements OnInit {

  constructor(private company: CompanyService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

      if (params['code']) {

        this.company.sendRequestToAddFacebookAccount$(params['code']).subscribe(
          res => {
            if (res.statusCode === '100') {
              this.router.navigate(['/main/new-application/marketing'], { relativeTo: this.route, queryParams: { facebookAcc: 'Success' } });
            }
            else {
              this.router.navigate(['/main/new-application/marketing'], { relativeTo: this.route, queryParams: { err: 'No Facebook Account' } })
            }
          },
          error => {
            let message
            error.error.message === 'Not an Facebook account!'
              && (message = 'No Facebook Account')
              || (message = 'Something went wrong')

            this.router.navigate(['/main/new-application/marketing'], {
              relativeTo: this.route,
              queryParams: { err: message }
            })
          }
        )

      }
      else {
        this.router.navigate(['/main/new-application/marketing'], { relativeTo: this.route, queryParams: { err: 'No Ad Account' } })
      }

    });
  }

}
