import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        let currentUser = localStorage.getItem('userToken');
        let url = req.url
        if ((url.indexOf("login") == -1 || url.indexOf("thanks-for-feedback") == -1) && !url.includes("https://portal.finbox.in/bank-connect")) {
            var authRequest = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser}`
                }
            });
            return next.handle(authRequest).pipe(tap(response=>{
            },
            error=>{
                if( error.error.error === 'Token Unauthorized' ){
                    //window.location.href = 'https://dashboard.getvantage.co';
                    this.router.navigate(['/']);
                }
            }))
        } else {
            return next.handle(req.clone());
          }
    }
}