import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { FetchAllPGAccounts, FetchCompanyEntityDetails } from '../actions/company.action';

export class PGStateModel {
    isPGAccountsLoaded: boolean

    pgAccounts:         Array<any>
}

@State<PGStateModel>({
  name: 'pgState',
  defaults: {
    isPGAccountsLoaded: false,
    
    pgAccounts: []
  },
})
@Injectable()
export class PGAccountsState {
  constructor( private util: Commons, private compService: CompanyService ) {}

  /* Selector to get selected application */
  @Selector()
  static getPgAccounts(state: PGStateModel) {
    return state.pgAccounts
  }

  @Selector()
  static isPGAccountsLoaded(state: PGStateModel) {
    return state.isPGAccountsLoaded
  }


  @Action(FetchAllPGAccounts)
  fetchPayments({getState, setState}: StateContext<FetchCompanyEntityDetails>){
    const state = getState()

    return this.compService.getAllPGAccounts$().pipe(tap(
        res=>{
            ( res.statusCode === "100" )
            && this.util.updateState( state, { isPGAccountsLoaded: true, pgAccounts: res.data }, setState )
            || this.util.updateState( state, { isPGAccountsLoaded: false, pgAccounts: [] }, setState )
        },
        error=> this.util.updateState( state, { isPGAccountsLoaded: false, pgAccounts: [] }, setState )
    ))

  }

}
