export class FetchAllApplications {
    static readonly type = '[FETCH ALL APPLICATIONS] GET'
}
export class FetchAllSpends {
    static readonly type = '[FETCH ALL SPENDS] GET'
    constructor(public payload: any) { }
}
export class FetchAllCollections {
    static readonly type = '[FETCH ALL COLLECTIONS] GET'
    constructor(public payload: any) { }
}

export class FetchAllEscrows {
    static readonly type = '[FETCH ALL ESCROWS] GET'
    constructor(public payload: any) { }
}
export class FetchFundUtilizationData {
    static readonly type = '[FETCH FUND UTILIZATION] GET'
    constructor(public id: string) { }
}
export class PatchIsFirstApplicationCompleted {
    static readonly type = '[PATCH IS FIRST APPLICATION COMPLETED] PATCH'
}