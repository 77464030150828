import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianAmountFormat'
})
export class IndianAmountFormatPipe implements PipeTransform {

  transform(input: any, args: any){
    if (!input) return 0.00;
    else return input.toLocaleString('en-IN') 
  }

}
