import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-shopify-oauth',
  templateUrl: './shopify-oauth.component.html',
  styleUrls: ['./shopify-oauth.component.scss']
})
export class ShopifyOauthComponent implements OnInit {

  hmac: string;
  shop: string;
  code: string;
  checkShopifyOAuth: boolean = false
  timestamp: string;

  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthService, private company: CompanyService) { }

  ngOnInit(): void {
    this.hmac = this.route.snapshot.queryParams['hmac'];
    this.shop = this.route.snapshot.queryParams['shop']
    this.code = this.route.snapshot.queryParams['code']
    this.timestamp = this.route.snapshot.queryParams['timestamp']

    var shopifyParams = {
      "auth_code": this.code,
      "hmac": this.hmac,
      "timestamp": this.timestamp,
      "shop": this.shop,
    }
    localStorage.setItem('shopifyOAuthParams', JSON.stringify(shopifyParams))

    if (this.auth.isLoggedIn) {

      if (this.hmac && this.shop && this.code && this.timestamp) {
        this.checkAuth(shopifyParams)
      }
      else {
        var params = JSON.parse(localStorage.getItem('shopifyOAuthParams'))
        this.checkAuth(params)
      }

    }
    else {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: 'shopify-redirect' } });
    }

  }

  checkAuth(data) {
    this.company.checkShopifyOAuth$(data).subscribe(
      res => {
        if (res.message === 'Shopify authorization successfully') {
          this.getShopifyShopData()
        }
        else this.router.navigate(['/main/new-application/marketing'], { queryParams: { success: false } })
      },
      error => this.router.navigate(['/main/new-application/marketing'], { queryParams: { success: false } })
    )
  }

  getShopifyShopData() {
    this.company.getShopifyShopData$()
      .subscribe(data => {
        if (data.message == 'Shopify shop data added successfully') {
          this.getShopifyCustomerData()
        } else {
          this.router.navigate(['/main/new-application/marketing'], { queryParams: { success: false } })
        }
      },
        error => {
          console.log(error)
          this.router.navigate(['/main/new-application/marketing'], { queryParams: { success: false } })
        })
  }

  getShopifyCustomerData() {
    this.company.getShopifyCustomersData$()
      .subscribe(data => {
        if (data.message == 'Shopify customers data added successfully') {
          this.router.navigate(['/main/new-application/marketing'], { queryParams: { success: true } })
        } else {
          this.router.navigate(['/main/new-application/marketing'], { queryParams: { success: false } })
        }
      },
        error => {
          console.log(error)
          this.router.navigate(['/main/new-application/marketing'], { queryParams: { success: false } })
        })
  }

}
