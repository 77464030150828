import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchAllApplications, FetchAllSpends } from '../actions/application.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { ApplicationService } from '../../services/application.service';
import { SpendsResponse } from 'src/app/interfaces/spends-recovery.interface';
import { HttpErrorResponse } from '@angular/common/http';

export class SpendsStateModel {
    isAllSpendsLoaded: boolean
    
    allSpends: Array<any>
    totalSpendsPages: number
}

@State<SpendsStateModel>({
  name: 'spendsState',
  defaults: {
    isAllSpendsLoaded: false,

    allSpends: [],
    totalSpendsPages: 0
  },
})
@Injectable()
export class SpendsState {
  constructor( private appService: ApplicationService, private util: Commons ) {}

  /* Selector to get selected application */

  /* ==============================================card spends selector and action=================================== */
  
  @Selector()
  static getAllSpends(state: SpendsStateModel) {
    return{
      allSpends: state.allSpends,
      totalPages: state.totalSpendsPages
    };
  }

  @Selector()
  static isAllSpendsLoaded(state: SpendsStateModel) {
    return state.isAllSpendsLoaded
  }


  @Action(FetchAllSpends)
  fetchAllSpends({getState, setState}: StateContext<FetchAllSpends>, {payload}: FetchAllSpends){
    const state = getState()
    const { count } = payload
    return this.appService.getAllSpends$(payload).pipe(tap(
        (res: SpendsResponse)=>{
            ( res.statusCode === "100" )
            && this.util.updateState( state, 
              { 
                isAllSpendsLoaded: true, 
                allSpends: res.data.cardSpends,
                totalSpendsPages: Math.ceil( res.data.totalCount/count )
              }, 
              setState ) 
            || this.util.updateState( state, { isAllSpendsLoaded: false, allSpends: [] }, setState )
        },
        (error: HttpErrorResponse)=> this.util.updateState( state, { isAllSpendsLoaded: false, allSpends: [] }, setState )
    ))
  }

  /* ==============================================card spends selector and action end=================================== */

}
