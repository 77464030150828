import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet>
      <ngx-ui-loader></ngx-ui-loader>
      <app-raise-query></app-raise-query>
    </router-outlet>
  `
})
export class AppComponent {
  title = 'GetGrowth Capital ';
}
