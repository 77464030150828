import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PipesAndDirectivesModule } from "../pipes-and-directives/pipes-and-directives.module";
import { RouterModule } from '@angular/router';
import { RaiseQueryComponent } from './header/raise-query/raise-query.component';
import { AccountActivityComponent } from './header/account-activity/account-activity.component';
import { Commons } from '../utils/commons';
import { SkeletonComponent } from './skeleton/skeleton.component';
/* material imports */
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSidenavModule} from '@angular/material/sidenav';

/* material imports end */


@NgModule({
  declarations: [PaginationComponent, HeaderComponent, SidebarComponent, RaiseQueryComponent, AccountActivityComponent, SkeletonComponent],
  imports: [
    PipesAndDirectivesModule,
    MatSidenavModule,
    RouterModule,
    MatSnackBarModule,
    FormsModule,
    CommonModule,
    MatNativeDateModule, 
    MatRippleModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressBarModule
  ],
  exports:[
    PaginationComponent, HeaderComponent, SidebarComponent, 
    MatSidenavModule,
    MatSnackBarModule,
    PipesAndDirectivesModule,
    SkeletonComponent,
    MatNativeDateModule, 
    MatRippleModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressBarModule,
    RaiseQueryComponent,
    FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers:[Commons]
})
export class SharedModuleModule { }
