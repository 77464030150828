import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchFormStepStatus, FetchNdaDetails } from '../actions/company.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { NdaDetails } from '../../interfaces/entity.interface';

export class NdaStateModel {
    isNdaLoaded: boolean
    ndaDetails:  NdaDetails
}

@State<NdaStateModel>({
    name: 'ndaState',
    defaults: {
        isNdaLoaded: false,
        
        ndaDetails:  null,
    },
})
@Injectable()
export class NdaState {
    constructor( private util: Commons, private company: CompanyService ) {}

    /* Selector to get selected application */
    /* ==============================================application list selector and action=================================== */
    @Selector()
    static getNdaDetails(state: NdaStateModel) {
        return state.ndaDetails
    }

    @Selector()
    static isNdaLoaded(state: NdaStateModel) {
        return state.isNdaLoaded
    }


    @Action(FetchNdaDetails)
    fetchNda({getState, setState}: StateContext<FetchNdaDetails>){
        const state = getState()

        return this.company.getNdaDetails$().pipe(tap(
            res=>{
                ( res.statusCode === "100" )
                && this.util.updateState( state, { isNdaLoaded: true, ndaDetails: res.data }, setState )
                || this.util.updateState( state, { isNdaLoaded: false, ndaDetails: null }, setState )
            },
            error=> this.util.updateState( state, { isNdaLoaded: false, ndaDetails: null }, setState )
        ))
    }
  /* ==============================================application list selector and action end=================================== */

}
