import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { CompanyService } from "../../services/company.service";
import { FetchCompanyEntityDetails } from '../actions/company.action';

export class EntityStateModel {
    isEntityDataLoaded: boolean

    entityData: any
}

@State<EntityStateModel>({
  name: 'entityState',
  defaults: {
    isEntityDataLoaded: false,
    
    entityData: []
  },
})
@Injectable()
export class EntityState {
  constructor( private util: Commons, private compService: CompanyService ) {}

  /* Selector to get selected application */
  @Selector()
  static getEntityData(state: EntityStateModel) {
    return state.entityData
  }

  @Selector()
  static isEntityDataLoaded(state: EntityStateModel) {
    return state.isEntityDataLoaded
  }


  @Action(FetchCompanyEntityDetails)
  fetchPayments({getState, setState}: StateContext<FetchCompanyEntityDetails>){
    const state = getState()

    return this.compService.getEntityDetails$().pipe(tap(
        res=>{
          ( res.statusCode === "100" )
          && this.util.updateState( state, { isEntityDataLoaded: true, entityData: res['data'] }, setState )
          || this.util.updateState( state, {
            isEntityDataLoaded: false, 
            entityData :{
              cin: null,
              gstin: null,
              pan: null
            }
          }, setState )
        },
        error=> this.util.updateState( state, {
          isEntityDataLoaded: false, 
          entityData :{
            cin: null,
            gstin: null,
            pan: null
          }
        }, setState )
    ))

  }

}
