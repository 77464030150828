import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchAllPayments } from '../actions/payment.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { PaymentsService } from "../../services/payments.service";

export class PaymentsStateModel {
    isAllPaymentsLoaded: boolean

    payments: Array<any>
}

@State<PaymentsStateModel>({
  name: 'paymentsState',
  defaults: {
    isAllPaymentsLoaded: false,
    
    payments: []
  },
})
@Injectable()
export class PaymentsState {
  constructor( private paymentService: PaymentsService, private util: Commons ) {}

  /* Selector to get selected application */
  @Selector()
  static getPayments(state: PaymentsStateModel) {
    return state.payments
  }

  @Selector()
  static isPaymentsLoaded(state: PaymentsStateModel) {
    return state.isAllPaymentsLoaded
  }


  @Action(FetchAllPayments)
  fetchPayments({getState, setState}: StateContext<FetchAllPayments>){
    const state = getState()

    return this.paymentService.fetchAllPayments().pipe(tap(
        res=>{
            ( res.statusCode == "100" )
            && this.util.updateState( state, { isAllPaymentsLoaded: true, payments: res['data'] }, setState )
            || this.util.updateState( state, { isAllPaymentsLoaded: false, payments: [] }, setState )
        },
        error=> this.util.updateState( state, { isAllPaymentsLoaded: false, payments: [] }, setState )
    ))

  }

}
