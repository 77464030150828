import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchAllApplications, FetchAllSpends, PatchIsFirstApplicationCompleted } from '../actions/application.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { ApplicationService } from '../../services/application.service';
import { ApplicationResponse } from 'src/app/interfaces/application.interface';
import { HttpErrorResponse } from '@angular/common/http';

export class ApplicationsStateModel {
    isAllApplicationsLoaded: boolean
    isFirstApplication: boolean
    isFirstApplicationCompleted: boolean
    allowAppCreation: boolean
    isPreApprovedLimitApplicable:boolean
    preApprovedLimit:number;
    preApprovedFor:string;
    applications: Array<string>
}

@State<ApplicationsStateModel>({
  name: 'applicationsState',
  defaults: {
    isAllApplicationsLoaded: false,
    isFirstApplication: false,
    isFirstApplicationCompleted: false,
    isPreApprovedLimitApplicable:false,
    preApprovedLimit:0,
    preApprovedFor:'',
    allowAppCreation: false,
    applications: [],
  },
})
@Injectable()
export class ApplicationsState {
  constructor( private appService: ApplicationService, private util: Commons ) {}

  /* Selector to get selected application */
  /* ==============================================application list selector and action=================================== */
  @Selector()
  static getApplications(state: ApplicationsStateModel) {
    return{
      applications: state.applications,
      isFirstApplication: state.isFirstApplication,
      isFirstApplicationCompleted: state.isFirstApplicationCompleted,
      isPreApprovedLimitApplicable:state.isPreApprovedLimitApplicable,
      preApprovedLimit:state.preApprovedLimit,
      preApprovedFor:state.preApprovedFor,
      allowAppCreation: state.allowAppCreation
    }
  }

  @Selector()
  static isApplicationsLoaded(state: ApplicationsStateModel) {
    return state.isAllApplicationsLoaded
  }


  @Action(FetchAllApplications)
  fetchApplications({getState, setState}: StateContext<ApplicationsStateModel>){
    const state = getState()

    return this.appService.getAllApplications$().pipe(tap(
        (res: ApplicationResponse)=>{
            ( res.statusCode == "100" )
            && this.util.updateState( state, 
              { 
                isAllApplicationsLoaded: true, 
                applications: res.data.applications, 
                isFirstApplication: res.data.isFirstApplication,
                isFirstApplicationCompleted: res.data.isFirstApplicationCompleted,
                isPreApprovedLimitApplicable: res.data.isPreApprovedLimitApplicable,
                preApprovedLimit: res.data.preApprovedLimit,
                preApprovedFor: res.data.preApprovedFor,
                allowAppCreation: res.data.allowAppCreation
              }, setState )
            || this.util.updateState( state, 
              { 
                isAllApplicationsLoaded: false, 
                applications: [],
                isFirstApplication: false,
                isFirstApplicationCompleted: false,
                isPreApprovedLimitApplicable: false,
                preApprovedLimit: 0,
                preApprovedFor: '',
                allowAppCreation: false
              }, setState )
        },
        (error: HttpErrorResponse)=> this.util.updateState( state, 
          { 
            isAllApplicationsLoaded: false, 
            applications: [],
            isFirstApplication: false,
            isFirstApplicationCompleted: false,
            isPreApprovedLimitApplicable: false,
            preApprovedLimit: 0,
                preApprovedFor: '',
            allowAppCreation: false
          }, setState )
    ))
  }
  /* ==============================================application list selector and action end=================================== */

  @Action(PatchIsFirstApplicationCompleted)
  patchFirstApplicationCompleted({getState, setState}: StateContext<ApplicationsStateModel>){
    const state = getState()
    return this.util.updateState( state, { isFirstApplicationCompleted: true }, setState)
  }

}
