import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchAllCollections } from '../actions/application.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { ApplicationService } from '../../services/application.service';
import { RecoveryResponse } from 'src/app/interfaces/spends-recovery.interface';
import { HttpErrorResponse } from '@angular/common/http';

export class CollectionsStateModel {
    isAllCollectionsLoaded: boolean
    
    allCollections: any[]
    totalCollectionsPages: number
}

@State<CollectionsStateModel>({
  name: 'collectionsState',
  defaults: {
    isAllCollectionsLoaded: false,

    allCollections: [],
    totalCollectionsPages: 0
  },
})
@Injectable()
export class CollectionsState {
  constructor( private appService: ApplicationService, private util: Commons ) {}

  /* Selector to get selected application */

  /* ==============================================card Collections selector and action=================================== */
  
  @Selector()
  static getAllCollections(state: CollectionsStateModel) {
    return{
      allCollections: state.allCollections,
      totalPages: state.totalCollectionsPages
    };
  }

  @Selector()
  static isAllCollectionsLoaded(state: CollectionsStateModel) {
    return state.isAllCollectionsLoaded
  }


  @Action(FetchAllCollections)
  fetchAllCollections({getState, setState}: StateContext<FetchAllCollections>, {payload}: FetchAllCollections){
    const state = getState()
    const { count } = payload
    return this.appService.getAllCollectionData$(payload).pipe(tap(
        (res: RecoveryResponse)=>{
            ( res.statusCode === "100" )
            && this.util.updateState( state, 
              { 
                isAllCollectionsLoaded: true, 
                allCollections: res.data.collectionData,
                totalCollectionsPages: Math.ceil( res.data.totalCount/count )
              }, 
              setState )
            || this.util.updateState( state, { isAllCollectionsLoaded: false, allCollections: [] }, setState )
        },
        (error: HttpErrorResponse)=> this.util.updateState( state, { isAllCollectionsLoaded: false, allCollections: [] }, setState )
    ))
  }

  /* ==============================================card Collections selector and action end=================================== */

}
