import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() currentPage: number;
  @Input() totalPageCount: number;
  @Output() fetchDataEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  incrementPage() {
    if (this.currentPage <= this.totalPageCount - 1) {
      this.currentPage++;
      this.fetchDataEvent.emit(this.currentPage.toString())
    }
  }

  decrementPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchDataEvent.emit(this.currentPage.toString())
    }
  }

}
