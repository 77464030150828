import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchAllVendors } from '../actions/vendor.action';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { VendorsService } from "../../services/vendor.service";

export class VendorsStateModel {
    isAllVendorsLoaded: boolean

    vendors: Array<any>
}

@State<VendorsStateModel>({
  name: 'vendorsState',
  defaults: {
    isAllVendorsLoaded: false,
    
    vendors: []
  },
})
@Injectable()
export class VendorsState {
  constructor( private vendorService : VendorsService, private util: Commons ) {}

  /* Selector to get selected application */
  @Selector()
  static getVendors(state: VendorsStateModel) {
    return state.vendors
  }

  @Selector()
  static isVendorsLoaded(state: VendorsStateModel) {
    return state.isAllVendorsLoaded
  }


  @Action(FetchAllVendors)
  fetchPayments({getState, setState}: StateContext<FetchAllVendors>){
    const state = getState()

    return this.vendorService.fetchAllVendors$().pipe(tap(
        res=>{
            ( res.statusCode === "100" )
            && this.util.updateState( state, { isAllVendorsLoaded: true, vendors: res['data'] }, setState )
            || this.util.updateState( state, { isAllVendorsLoaded: false, vendors: [] }, setState )
        },
        error=> this.util.updateState( state, { isAllVendorsLoaded: false, vendors: [] }, setState )
    ))

  }

}
