import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Commons } from '../../utils/commons';
import { PatchStatementsForSelectedAccount } from "../actions/company.action";

export class StatementsStateModel {
    statements: any[]
    selectedAccount: any
}

@State<StatementsStateModel>({
    name: 'statementsState',
    defaults: {
        statements: [],
        selectedAccount: {
            bankName: '',
            accountNo: '',
        }
    },
})
@Injectable()
export class StatementsState {
    constructor( private util: Commons ) {}

    /* Selector to get selected application */
    @Selector()
    static getStatements(state: StatementsStateModel) {
        return{
            selectedAccount: state.selectedAccount,
            statements: state.statements
        }
    }


    @Action(PatchStatementsForSelectedAccount)
    patchStatements({getState, setState}: StateContext<PatchStatementsForSelectedAccount>, {statements, selectedAccount}: PatchStatementsForSelectedAccount){
        const state = getState()
        this.util.updateState( state, { statements, selectedAccount }, setState )
    }

}
