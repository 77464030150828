import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Config } from './config';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxMaskModule, IConfig } from 'ngx-mask';

/* store imports */
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { CustomHttpInterceptor } from './http-interceptor';
import { AuthenticationGuard } from './auth/guards/auth-guard';
import { ChildAuthGuard } from './auth/guards/child-auth.guard';

import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  POSITION,
  SPINNER,
} from 'ngx-ui-loader';
import { ShopifyOauthComponent } from './shopify-oauth/shopify-oauth.component';
import { GoogleOauthComponent } from './google-oauth/google-oauth.component';
import { FacebookOauthComponent } from './facebook-oauth/facebook-oauth.component';
import { RazorpayOauthComponent } from './razorpay-oauth/razorpay-oauth.component';

/* store imports end */

/* ngx ui loader */
import { PaymentsState } from './store/state/payments.state';
import { VendorsState } from './store/state/vendors.state';
import { ApplicationsState } from './store/state/application.state';
import { DealsState } from './store/state/deal.state';
import { SpendsState } from './store/state/spends.state';
import { CollectionsState } from './store/state/collection.state';
import { EntityState } from './store/state/entity.state';
import { FundUtilizationState } from './store/state/fund-utilization.state';
import { PGAccountsState } from './store/state/pg.state';
import { BankState } from './store/state/bank.state';
import { ItrState } from './store/state/itr.state';
import { MarketingState } from './store/state/marketing.state';
import { AssetsState } from './store/state/assets.state';
import { ObligationsState } from './store/state/obligations.state';
import { AnnualDocState } from './store/state/annual-doc.state';
import { GSTState } from './store/state/gst.state';
import { FormStepState } from './store/state/form-step.state';
import { NdaState } from './store/state/nda.state';
import { StatementsState } from './store/state/statements.state';
import { EscrowsState } from './store/state/escrow.state';
// import { CibilCheckComponent } from './main/protected-components/cibil/cibil-check/cibil-check.component';
const states: Array<any> = [
  PaymentsState,
  VendorsState,
  ApplicationsState,
  DealsState,
  SpendsState,
  CollectionsState,
  FundUtilizationState,
  EntityState,
  PGAccountsState,
  BankState,
  ItrState,
  MarketingState,
  AssetsState,
  ObligationsState,
  AnnualDocState,
  GSTState,
  FormStepState,
  NdaState,
  StatementsState,
  EscrowsState
];

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'var(--main-color)',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.squareJellyBox, // background spinner type
  fgsType: SPINNER.squareJellyBox, // progress bar thickness
};

/* ngx ui loader end */

const config: SocketIoConfig = {
  url: new Config().getSocketUrl(),
  options: {},
};

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'main',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'redirect',
    canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./redirections/redirections.module').then(
        (m) => m.RedirectionsModule
      ),
  },
  {
    path: 'shopify-redirect',
    component: ShopifyOauthComponent,
  },
  {
    path: 'google-redirect',
    component: GoogleOauthComponent,
  },
  {
    path: 'facebook-redirect',
    component: FacebookOauthComponent,
  },
  {
    path: 'razorpay-redirect',
    component: RazorpayOauthComponent,
  },
  { path: '**', redirectTo: 'auth' }
];
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    ShopifyOauthComponent,
    GoogleOauthComponent,
    FacebookOauthComponent,
    RazorpayOauthComponent,
    // CibilCheckComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    NgxMaskModule.forRoot(options),
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModuleModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    NgxsModule.forRoot(states),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    SocketIoModule.forRoot(config),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  providers: [
    AuthenticationGuard,
    ChildAuthGuard,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor, // change this line with your custom http interceptor
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
