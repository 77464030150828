import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-razorpay-oauth',
  template: `
      <div class="d-grid text-center main" style="height: 100vh;place-content: center;">
        <h4>Redirecting...</h4>
        <h4>Please wait while you are being redirected</h4>
        <h4>Kindly do not refresh the page.</h4>
    </div>`
})
export class RazorpayOauthComponent implements OnInit {

  constructor( private route: ActivatedRoute, private router: Router, private company: CompanyService ) {
    this.route.queryParams.subscribe(
      params=>{
        let auth_code = params['code']
        let pgAccountId = localStorage.getItem('identity')

        if( auth_code ){
          let payload = { auth_code, pgAccountId }
          company.checkRazorpayOAuth$( payload ).subscribe(
            response=>{
              if( response.statusCode === '100' ){
                this.router.navigate(['/main/new-application/payment-gateways'], { relativeTo: this.route, queryParams: { success: true } })
              }
              else{
                this.router.navigate(['/main/new-application/payment-gateways'], { relativeTo: this.route, 
                  queryParams: { success: false, err: response.message || 'Failed to complete OAUTH for razorpay!'  } }
                )
              }
            },
            error=>{
              this.router.navigate(['/main/new-application/payment-gateways'], { relativeTo: this.route, 
                queryParams: { success: false, err: error.error.message || 'Failed to complete OAUTH for razorpay!'  } }
              )
            }
          )
        }
        else{
          this.router.navigate(['/main/new-application/payment-gateways'], { relativeTo: this.route, queryParams: { success: false } })
        }

        localStorage.removeItem('identity')

      }
    )
  }

  ngOnInit(): void {
  }

}
